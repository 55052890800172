import React from "react";
import { observer } from "mobx-react";
import TableComponent from "./components/Table";
import { withRouter } from "react-router-dom";
import { Form, Select, Radio, Button, message, Tooltip, Cascader } from "antd";
import { cloneDeep } from "lodash";
import _ from "lodash";
import "./style/list.less";

const Container = Loader.loadBusinessComponent("SystemWrapper");
const BreadCrumb = Loader.loadBaseComponent("BreadCrumb");
const IconFont = Loader.loadBaseComponent("IconFont");
const ModalOrgSelect = Loader.loadBaseComponent("ModalComponent");
const OrgTree = Loader.loadBusinessComponent("OrgTree");
const ModalSetPointMap = Loader.loadBusinessComponent("ModalSetPointMap");
const Search = Loader.loadBaseComponent("SearchInput");
const AuthComponent = Loader.loadBusinessComponent("AuthComponent");
const { deviceStatus, deviceAndMjType, deviceLocation, locationType, industry, wifi } = Dict.map;
const Option = Select.Option;
const RadioGroup = Radio.Group;
const FormItem = Form.Item;

@withRouter
@Decorator.businessProvider("deviceManagement", "user", "organization", "device", "place")
@Decorator.withEntryLog()
@observer
class DeviceListView extends React.Component {
  state = {
    list: [],
    loading: false,
    deviceGroup: [],
    selectedRowKeys: [],
    showOrgModal: false,
    showMapModal: false,
    changeDevices: [],
    autoExpandParent: true,
    expandedKeys: [],
    ifInclude: 0,
    selectKeys: [],
    exportOptions: {},
    placeTypeInLevel: [],
    pathInfo: []
  };

  allIndustry = [];
  allbigDatePlaceType = [];
  deviceAndMjType = []; // 设备类型
  tipsRef = React.createRef();
  componentWillMount() {
    this.deviceAndMjType = deviceAndMjType;
    if (!BaseStore.menu.getInfoByName("wifiLibrary")) {
      this.deviceAndMjType = deviceAndMjType.filter(v => v.value !== wifi.value);
    }
    const { deviceManagement } = this.props;
    deviceManagement.initData();
    SocketEmitter.on(SocketEmitter.eventName.editDeviceInfo, this.subDeviceEdit);
    this.getPlaceTypeInLevel(locationType);
    this.allIndustry = [].concat([{ value: "-1", label: "全部" }], industry);
    /**获取羚羊云设备分组 */
    Service.device.queryDeviceGroup().then(res => {
      const result = res.data.groups || [];
      const deviceGroup = [].concat([{ id: "-1", name: "全部分组" }], result);
      this.setState({
        deviceGroup
      });
    });
    this.expandKeys();
  }
  componentWillUnmount() {
    SocketEmitter.off(SocketEmitter.eventName.editDeviceInfo, this.subDeviceEdit);
  }

  onSelect = selectKeys => {
    this.setState({ selectKeys });
  };

  subDeviceEdit = () => {
    this.getDeviceList();
  };

  getPlaceTypeInLevel = data => {
    let newData = cloneDeep(data);
    var list = [];
    newData.map(item => {
      if (item.parentId && !item.isParent) {
        if (!list[item.parentId].children) {
          list[item.parentId].children = [];
        }
        list[item.parentId].children.push(item);
      } else {
        list[item.value] = item;
      }
    });
    var list2 = [{ value: "-1", label: "全部类型" }];
    list.map(item => {
      list2.push(item);
    });
    this.setState({
      placeTypeInLevel: list2
    });
  };

  /**
   * 选中树操作
   */
  leafClk(key) {
    const { deviceManagement } = this.props;
    if (key.length !== 0) {
      deviceManagement.setData({
        activeKey: key
      });
    }

    this.getDeviceList({ offset: 0 });
  }

  /**
   * 打开分配modal 设置分配的设备id
   */
  showOrgAction = async (item, type = "single") => {
    const { deviceManagement } = this.props;
    let ids = [];
    let selectKeys = [];
    let expandedKeys = null;
    selectKeys = deviceManagement.activeKey;
    await Service.organization.organizationInfo(deviceManagement.activeKey[0]).then(res => {
      if (res.data.parentId) {
        expandedKeys = [res.data.parentId, deviceManagement.activeKey[0]];
      }
    });
    if (type == "single") {
      ids = [item.id];
    } else {
      ids = item;
    }
    this.setState({
      expandedKeys,
      changeDevices: ids,
      selectKeys,
      showOrgModal: true,
      selectedRowKeys: []
    });
  };

  /**
   * 关闭分配modal 清空分配的设备id
   */
  closeOrgAction = () => {
    this.setState({
      changeDevices: [],
      showOrgModal: false
    });
  };

  /**
   * 打开点位设置
   */
  showMapAction = devices => {
    let device = devices[0];
    const { pathInfo } = this.state;
    let placeInfo = pathInfo.find(p => p.id == device.placeId) || {};
    device.areaName = placeInfo.areaName || "";
    let changeDevices = cloneDeep(devices);
    this.setState({
      changeDevices,
      showMapModal: true
    });
  };

  /**
   * 关闭分配modal 清空分配的设备id
   */
  closeMapAction = () => {
    this.setState({
      changeDevices: [],
      showMapModal: false
    });
  };

  /**
   * 确认提交设备修改组织
   */
  submitChangeOrg = async info => {
    const { deviceManagement } = this.props;
    let { changeDevices } = this.state;
    let options = {
      toOrganizationId: info[0]
    };
    let orgId = deviceManagement.activeKey;
    let fromOrganizations = [];
    changeDevices.map((v, k) => {
      fromOrganizations.push({
        deviceId: changeDevices[k],
        fromOrganizationId: orgId[0]
      });
    });
    if (orgId == info[0]) {
      return message.warn("设备已经在当前组织下！");
    }
    options.fromOrganizations = fromOrganizations;
    return Service.device.updateOrganizationDevicesBatch(options).then(() => {
      message.success("操作成功！");
      // Shared.queryUserDevice();
      this.closeOrgAction();
      this.setState({ selectedRowKeys: [] });
      this.getDeviceList();
      Shared.queryOrganizationDevice();
    });
  };

  /**
   * 地图标注
   */
  submitDevicePoint = updateGeoAndPlaces => {
    this.getDeviceList();
    this.closeMapAction();
  };

  /**
   * 列表查询 
   * orgIds: 父组织 ID 及子组织 ID 的集合
   * orgId: 父组织ID
   */
  getList = (orgIds, orgId) => {
    const { deviceManagement } = this.props;
    let searchData = cloneDeep(deviceManagement.searchData);
    _.forIn(searchData, (value, key) => {
      if (value === "" || value === null || value === undefined || value === "-1") {
        searchData = _.omit(searchData, [key]);
      }
    });
    if (orgIds.length) {
      searchData.orgIds = orgIds;
    } else {
      searchData.orgIds = deviceManagement.activeKey;
    }
    if (searchData.deviceTypes) {
      searchData.deviceTypes = searchData.deviceTypes.split(",");
      // if(searchData.deviceTypes[0]==='100603'){
      //   searchData.deviceTypes.push('100607')
      // }
    } else {
      let deviceTypes = [];
      this.deviceAndMjType
        .filter(v => v.value !== "-1")
        .map(v => {
          deviceTypes = deviceTypes.concat(v.value.split(","));
          return v;
        });
      searchData.deviceTypes = deviceTypes;
    }
    this.setState({
      exportOptions: searchData
    });
    return Service.device.deviceListByOrganization(searchData, orgId);
  };

  /**
   * 查询设备列表
   *
   */
  getDeviceList = async (options = {}) => {
    this.setState({ loading: true });
    const { deviceManagement } = this.props;
    let placeIds = [];
    deviceManagement.mergeSearchData(options);
    let searchData = deviceManagement.searchData;
    let orgIds = [];
    let pathInfo = [];
    let orgId;
    if (searchData.includeSubOrganizations) {
      orgId = deviceManagement.activeKey[0];
      await Service.organization
        .queryChildOrganizationsById({
          selectChildOrganization: true,
          id: orgId
        })
        .then(res => {
          res.data &&
            res.data.map(v => {
              orgIds.push(v.id);
            });
        });
    }
    this.getList(orgIds, orgId).then(res => {
      let list = res.data.list;
      if (list.length) {
        list.map(v => {
          if (v.placeId) {
            placeIds.push(v.placeId);
          }
        });
        BSConfig.microService.place &&
          Service.place.getPlacesByConditions({ placeIds }).then(res => {
            if (res.data) {
              pathInfo = res.data;
            }
            this.setState({
              pathInfo
            });
          });
      }
      this.setState({
        list: list,
        total: res.data.total,
        loading: false
      });
    });
  };
  /**
   * 导出设备列表
   */
  exportDeviceList = () => {
    const { deviceManagement, organization } = this.props;
    this.setState({
      loading: true
    });
    const activedOrgId = deviceManagement.activeKey[0];
    let orgName = "";
    organization.orgArray.find(v => {
      if (v.id == activedOrgId) {
        orgName = v.name;
      }
    });
    let exportOptions = { limit: 99999 };
    exportOptions.orgName = orgName;
    exportOptions.organizationId = activedOrgId;
    let options = Object.assign({}, this.state.exportOptions, exportOptions);
    Service.device.exportDevice(options).then(res => {
      this.setState({
        loading: false
      });
    });
  };

  /**分页切换查询 */
  onChange = (page, pageSize) => {
    this.getDeviceList({
      limit: pageSize,
      offset: (page - 1) * pageSize
    });
  };

  // 包含子组织
  handleIfInclude = ({ value }) => {
    this.setState({
      ifInclude: value
    });
    this.getDeviceList({ includeSubOrganizations: value, offset: 0 });
  };

  /**
   * 重置表单
   */

  reset = () => {
    const { form, deviceManagement } = this.props;
    deviceManagement.initSearchForm();
    form.setFieldsValue({ ...deviceManagement.searchData });
    this.getDeviceList();
  };

  /**
   * table 多选
   */
  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  expandKeys = () => {
    const { organization } = this.props;
    const orgAll = organization.orgTreeData;
    var expandkeys = [];
    orgAll.map(v => {
      if (v.children) {
        expandkeys.push(v.id);
      }
    });
    this.setState({
      expandedKeys: expandkeys,
      autoExpandParent: false
    });
  };

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys,
      autoExpandParent: false
    });
  };

  render() {
    const { organization, deviceManagement, form } = this.props;
    let { list, total, loading, deviceGroup, selectedRowKeys, showOrgModal, showMapModal, changeDevices, ifInclude, expandedKeys, placeTypeInLevel, pathInfo } = this.state;

    const { searchData } = deviceManagement;
    let hasRowSelection = !!BaseStore.menu.getInfoByName('deviceAllocate');
    const rowSelection = hasRowSelection ? {
      columnWidth: 24,
      selectedRowKeys,
      onChange: this.onSelectChange
    } : null
    return (
      <>
        <div ref={this.tipsRef} className="tips-layout" />
        <Container
          width="85%"
          Treetitle="设备管理"
          treeActiveKey={deviceManagement.activeKey}
          leafClk={this.leafClk.bind(this)}
          className="device-setting-wrapper"
          leftOrgTree={true}
          TreeChildren={
            <div className="childrenOrg">
              <div>包含子组织:</div>
              <RadioGroup value={ifInclude} onChange={e => this.handleIfInclude({ value: e.target.value, containSuborganization: 0 })}>
                <Radio value={1}>包含</Radio>
                <Radio value={0}>不包含</Radio>
              </RadioGroup>
            </div>
          }
          breadCrumb={
            cloneDeep(deviceManagement.activeKey) && <BreadCrumb list={this.props.organization.getParentOrgListByOrgId(cloneDeep(deviceManagement.activeKey)[0]).reverse()} />
          }
        >
          <div className="device-table-content">
            <div className="serach-form">
              <AuthComponent actionName="deviceManagement">
                <Button type="primary" onClick={() => this.exportDeviceList({ page: 1 })}>
                  <IconFont type="icon-S_Edit_AllocationOne" />
                  导出
                </Button>
              </AuthComponent>
              <AuthComponent actionName="deviceAllocate">
                <Tooltip getPopupContainer={() => this.tipsRef.current} title="分配组织">
                  <Button type="primary" onClick={() => this.showOrgAction(selectedRowKeys, "multiple")} disabled={selectedRowKeys.length === 0}>
                    <IconFont type="icon-S_Edit_Export" />
                    分配组织
                  </Button>
                </Tooltip>
              </AuthComponent>
              <FormGroupLayout
                getDeviceList={this.getDeviceList}
                placeTypeInLevel={placeTypeInLevel}
                deviceGroup={deviceGroup}
                searchData={searchData}
                allIndustry={this.allIndustry}
                deviceAndMjType={this.deviceAndMjType}
                // allbigDatePlaceType={this.allbigDatePlaceType}
              />
            </div>
            <TableComponent
              rowSelection={rowSelection}
              key="device"
              total={total}
              industry={industry}
              bigDatePlaceType={locationType}
              searchData={searchData}
              pathInfo={pathInfo}
              dataSource={list}
              loading={loading}
              onChange={this.onChange}
              changeOrg={this.showOrgAction}
              changePoint={this.showMapAction}
              rowKey={"id"}
              scroll={{ y: "100%" }}
            />
          </div>
          <ModalOrgSelect
            title="分配到组织机构"
            visible={showOrgModal}
            onCancel={this.closeOrgAction}
            destroyOnClose={true}
            onOk={() => this.submitChangeOrg(this.state.selectKeys)}
            className="device-allocate-org"
          >
            <OrgTree onSelect={this.onSelect} activeKey={this.state.selectKeys} expandedKeys={expandedKeys} />
          </ModalOrgSelect>
          {showMapModal && (
            <ModalSetPointMap
              title="地图标注"
              visible={showMapModal}
              clickEvent={true}
              destroyOnClose={true}
              showSearch={true}
              point={changeDevices[0]}
              onCancel={this.closeMapAction}
              onOk={this.submitDevicePoint}
              showPlaceModal={true}
            />
          )}
        </Container>
      </>
    );
  }
}

@withRouter
@Decorator.businessProvider("deviceManagement")
@observer
@Form.create({
  onFieldsChange: (props, files) => {
    const { deviceManagement } = props;
    let data = {};
    let searchData = deviceManagement.searchData;
    Object.keys(files).map(key => {
      data[key] = files[key].value;
    });
    data.placeIds = data.placeIds ? data.placeIds.split(",") : "";
    data.hadLocation = data.hadLocation === "1" ? true : data.hadLocation === "-1" ? null : data.hadLocation === "0" ? false : searchData.hadLocation;
    data.pathId = data.pathId ? (data.pathId[0] == "-1" ? undefined : data.pathId) : searchData.pathId;
    deviceManagement.mergeSearchData(data);
    props.getDeviceList({ offset: 0 });
  }
})
class FormGroupLayout extends React.Component {
  render() {
    const { form, deviceGroup, allIndustry, placeTypeInLevel, deviceAndMjType } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Form layout="inline">
        <FormItem>
          <Tooltip title="项目分组">
            {getFieldDecorator("lygroupId")(
              <Select placeholder="项目分组">
                {deviceGroup.map(item => (
                  <Option key={item.id} value={item.id} title={`${item.name}`}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            )}
          </Tooltip>
        </FormItem>
        <FormItem>
          <Tooltip title="设备状态">
            {getFieldDecorator("deviceStatus")(
              <Select placeholder="设备状态">
                {deviceStatus.map(item => (
                  <Option key={item.value} value={item.value} title={`${item.label}`}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            )}
          </Tooltip>
        </FormItem>
        <FormItem>
          <Tooltip title="设备类型">
            {getFieldDecorator("deviceTypes")(
              <Select placeholder="设备类型">
                {deviceAndMjType.map(item => (
                  <Option key={item.value} value={item.value} title={`${item.label}`}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            )}
          </Tooltip>
        </FormItem>
        <FormItem className="item-input">
          <Tooltip title="所在区域类型">
            {getFieldDecorator("pathId")(
              // <Select placeholder="所在区域类型">
              //   {allbigDatePlaceType.map(item => (
              //     <Option key={item.value} value={item.value} title={`${item.label}`}>
              //       {item.label}
              //     </Option>
              //   ))}
              // </Select>

              <Cascader
                options={placeTypeInLevel}
                placeholder="所在区域类型"
                showSearch
                // {...isDisabled}
              />
            )}
          </Tooltip>
        </FormItem>
        <FormItem>
          <Tooltip title="所属行业">
            {getFieldDecorator("industry1")(
              <Select placeholder="所属行业">
                {allIndustry.map(item => (
                  <Option key={item.value} value={item.value} title={`${item.label}`}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            )}
          </Tooltip>
        </FormItem>
        <FormItem>
          <Tooltip title="经纬度">
            {getFieldDecorator("hadLocation")(
              <Select placeholder="经纬度">
                {deviceLocation.map(item => (
                  <Option key={item.value} value={item.value} title={`${item.label}`}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            )}
          </Tooltip>
        </FormItem>
        <FormItem className="item-input">
          <Tooltip title="请输入名称/SN/CID/箱体号查询">{getFieldDecorator("keywords")(<Search placeholder="请输入名称/SN/CID/箱体号查询" className="device-search" />)}</Tooltip>
        </FormItem>
      </Form>
    );
  }
}
export default DeviceListView;
