(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("antd"), require("_"), require("mobxReact"), require("ReactRouterDOM"));
	else if(typeof define === 'function' && define.amd)
		define("deviceView", ["React", "antd", "_", "mobxReact", "ReactRouterDOM"], factory);
	else if(typeof exports === 'object')
		exports["deviceView"] = factory(require("React"), require("antd"), require("_"), require("mobxReact"), require("ReactRouterDOM"));
	else
		root["deviceView"] = factory(root["React"], root["antd"], root["_"], root["mobxReact"], root["ReactRouterDOM"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__) {
return 